import React from 'react';
import { JobOffer } from 'views/careers/job-offer';
import { SEO } from 'components/seo';
import messages from 'constants/translations/careers.json';
import { graphql } from 'gatsby';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const JobOfferTemplate = ({ data, location }) => (
    <Layout location={location} messages={messages}>
        <JobOffer jobOffer={data.contentfulJobOffer} />
    </Layout>
);

const resolveTitleTagValue = (title) => {
    if (title) {
        return `Careers - ${title} - Mobile Reality`;
    }
    return 'view-team.JobOfferModal.jobOfferTitle';
};

export const Head = ({ data }) => {
    const job = data.contentfulJobOffer;
    const tags = {
        title: resolveTitleTagValue(job.title),
        description: job.metaDescription,
        ogTitle: job.title,
        ogType: 'website',
        ogUrl: `https://themobilereality.com/careers/${job.slug}`,
        ogImage:
            'https://mobilereality-assets.s3.eu-central-1.amazonaws.com/fb_baner.jpg',
        ogDescription: job.metaDescription,
    };

    return <SEO tags={tags} domainOgImage={false} />;
};

export default JobOfferTemplate;

JobOfferTemplate.propTypes = {
    location: object.isRequired,
    data: object.isRequired,
};

export const pageQuery = graphql`
    query ENJobOfferBySlug($slug: String!) {
        contentfulJobOffer(slug: { eq: $slug }) {
            slug
            title
            category
            price
            mainDeal
            areOtherDealsAvailable
            officeAddress
            typeOfWork
            description {
                raw
            }
            metaDescription
            technologies {
                technologies
            }
            benefitsText
            benefitsList {
                benefits
            }
            requirementsText
            requirementsList {
                requirements
            }
            whatYouCanGainText
            whatYouCanGainList {
                whatYouCanGain
            }
        }
    }
`;
