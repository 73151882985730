import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { UnderlinedSectionTitle } from 'components/underlined-section-title';
import { arrayOf, string } from 'prop-types';

import { Section } from './components/section';

const SContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.25rem;

    > :first-child {
        margin-bottom: 0;
    }
`;

export const AppliedPositionInfo = ({
    technologies,
    requirements,
    whatYouCanGain,
}) => {
    return (
        <SContainer>
            <UnderlinedSectionTitle label="view-job-offer.applied-position-info.title" />
            <Section
                title="view-job-offer.applied-position-info.mustHaves"
                data={requirements}
            />
            <Section
                title="view-job-offer.applied-position-info.technologies"
                data={technologies}
            />
            <Section
                title="view-job-offer.applied-position-info.whatYouGain"
                data={whatYouCanGain}
                secondary
            />
        </SContainer>
    );
};

AppliedPositionInfo.propsTypes = {
    technologies: arrayOf(string).isRequired,
    requirements: arrayOf(string).isRequired,
    whatYouCanGain: arrayOf(string).isRequired,
};
