import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const SContainer = styled(Container)`
    margin-bottom: 0;

    ${CONSTANTS.MEDIA.max1320`
        padding: 0;
    `}
`;

const SSubContainer = styled.div`
    height: 24.125rem;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
`;

const SFirstImage = styled.div`
    width: 31%;
    display: flex;
    position: absolute;
    bottom: 0;
    left: -2%;
    z-index: 0;

    ${CONSTANTS.MEDIA.max1320`
        transform: translate(-50%, 0);
        left: 20%;
    `}

    ${CONSTANTS.MEDIA.max1024`
        width: 45%;
        left: 30%;
    `}

    ${CONSTANTS.MEDIA.max768`
        width: 369px;
        left: 30%;
    `}

    ${CONSTANTS.MEDIA.max700`
        display: none;
    `}

    ${CONSTANTS.MEDIA.max500`
        display: initial;
        left: 25%;
        width: 192px;

       > :first-child {
            width: 192px !important;
        }

       * > img {
           object-position: 100%;
           width: 192px;
       }
    `};
`;

const SSecondImage = styled.div`
    width: 37%;
    display: flex;
    position: absolute;
    bottom: 0;
    left: 19%;
    z-index: 1;

    ${CONSTANTS.MEDIA.max1320`
        width: 40%;
        transform: translate(-50%, 0);
        left: 50%;
    `}

    ${CONSTANTS.MEDIA.max1024`
        width: 55%;
        left: 70%;
    `}

    ${CONSTANTS.MEDIA.max768`
        width: 438px;
        left: 65%;
    `}

    ${CONSTANTS.MEDIA.max700`
        left: 50%;
    `}

    ${CONSTANTS.MEDIA.max500`
        left: 60%;
        width: 292px;

       > :first-child {
            width: 292px !important;
        }

       * > img {
           object-position: 0;
           width: 292px;
       }
    `};
`;

const SThirdImage = styled.div`
    width: 31%;
    display: flex;
    position: absolute;
    bottom: 0;
    right: 23%;
    z-index: 1;

    ${CONSTANTS.MEDIA.max1320`
        transform: translate(-50%, 0);
        left: 80%;
    `}

    ${CONSTANTS.MEDIA.max1024`
        display: none;
    `}
`;

const STeamMemberBlack = styled.div`
    width: 35%;
    display: flex;
    position: absolute;
    bottom: 0;
    right: -2%;
    z-index: 0;

    ${CONSTANTS.MEDIA.max1320`
        display: none;
    `}
`;

export const FooterImages = () => {
    const { formatMessage } = useIntl();

    const images = useStaticQuery(graphql`
        {
            teamMemberRed: file(
                relativePath: { eq: "team/team-member-red.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 369
                        formats: [WEBP]
                        layout: FIXED
                    )
                }
            }
            strongman: file(relativePath: { eq: "team/strongman.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 438
                        formats: [WEBP]
                        layout: FIXED
                    )
                }
            }
            bookLover: file(relativePath: { eq: "team/book-lover.png" }) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 365
                        formats: [WEBP]
                        layout: FIXED
                    )
                }
            }
            teamMemberBlack: file(
                relativePath: { eq: "team/team-member-black.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        width: 417
                        formats: [WEBP]
                        layout: FIXED
                    )
                }
            }
        }
    `);

    return (
        <SContainer>
            <SSubContainer>
                <SFirstImage>
                    <GatsbyImage
                        alt={formatMessage({
                            id: 'careers-section.images.teamMemberInRed',
                        })}
                        image={
                            images.teamMemberRed.childImageSharp.gatsbyImageData
                        }
                    />
                </SFirstImage>
                <SSecondImage>
                    <GatsbyImage
                        alt={formatMessage({
                            id: 'careers-section.images.teamMemberInBlack',
                        })}
                        image={images.strongman.childImageSharp.gatsbyImageData}
                    />
                </SSecondImage>
                <SThirdImage>
                    <GatsbyImage
                        alt={formatMessage({
                            id: 'careers-section.images.teamMemberWithBook',
                        })}
                        image={images.bookLover.childImageSharp.gatsbyImageData}
                    />
                </SThirdImage>
                <STeamMemberBlack>
                    <GatsbyImage
                        alt={formatMessage({
                            id: 'careers-section.images.teamMemberWithLoupe',
                        })}
                        image={
                            images.teamMemberBlack.childImageSharp
                                .gatsbyImageData
                        }
                    />
                </STeamMemberBlack>
            </SSubContainer>
        </SContainer>
    );
};
