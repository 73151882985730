import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { func } from 'prop-types';

const SContainer = styled(Container)`
    margin-top: 19.375rem;
`;

const SSubContainer = styled.div`
    height: 19.375rem;
    max-width: 63.5rem;
    margin: auto;
    padding: 0 9.125rem;
    display: flex;
    flex: 1;
    align-items: center;
    gap: 5rem;
    border: 3px solid var(--additional-color);
    border-radius: 9.625rem;

    ${CONSTANTS.MEDIA.max1024`
        padding: 0 1rem 0;
    `}

    ${CONSTANTS.MEDIA.max900`
        gap: 1rem;
    `}

    ${CONSTANTS.MEDIA.max768`
        height: 48.125rem;
        flex-direction: column-reverse;
        border-radius: 6.625rem;
        padding: 5rem 3rem 0;
    `}

    ${CONSTANTS.MEDIA.max500`
        border-radius: 4rem;
    `}
`;

const SImageContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-bottom: 7.25rem;

    ${CONSTANTS.MEDIA.max768`
        margin-bottom: 0;
    `}
`;

const SContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 2rem;
    color: var(--white-color);

    ${CONSTANTS.MEDIA.max900`
        flex: 0.9;
    `}

    ${CONSTANTS.MEDIA.max768`
        align-items: center;
        text-align: center;
    `}
`;

const SButton = styled(Button)`
    max-width: 9rem;
    height: 3rem;
`;

export const ApplyNowBanner = ({ onClick }) => {
    const { formatMessage } = useIntl();

    const image = useStaticQuery(graphql`
        {
            mateusz: file(
                relativePath: { eq: "careers/job-offer/mateusz-banner.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        quality: 100
                        placeholder: NONE
                        layout: CONSTRAINED
                    )
                }
            }
        }
    `);

    return (
        <SContainer>
            <SSubContainer>
                <SImageContainer>
                    <GatsbyImage
                        alt={formatMessage({
                            id: 'view-job-offer.join-us.mateuszImage',
                        })}
                        image={image.mateusz.childImageSharp.gatsbyImageData}
                    />
                </SImageContainer>
                <SContentContainer>
                    <HeaderSecond>
                        <FormattedMessage id="view-job-offer.join-us.readyToJoin" />
                    </HeaderSecond>
                    <SButton variant="additional" onClick={onClick}>
                        <FormattedMessage id="view-job-offer.join-us.applyNow" />
                    </SButton>
                </SContentContainer>
            </SSubContainer>
        </SContainer>
    );
};

ApplyNowBanner.propTypes = {
    onClick: func.isRequired,
};
