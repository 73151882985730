import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderThird } from 'components/header-third';
import { node, string } from 'prop-types';

const SIconItemContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: var(--white-color);
`;

const SIconWrapper = styled.div`
    width: 6.125rem;
    height: 6.125rem;
    padding: 1.75rem;
    margin-bottom: 1.75rem;
    background: var(--tertiary-color-10);
    border-radius: 50%;
    text-align: center;
`;

const SIconHeader = styled(HeaderThird)`
    font-size: 1.375rem;
    margin-bottom: 1.75rem;
`;

export const IconItem = ({ children, title, info }) => {
    return (
        <SIconItemContainer>
            <SIconWrapper>{children}</SIconWrapper>
            <SIconHeader>
                <FormattedMessage id={title} />
            </SIconHeader>
            <FormattedMessage id={info} />
        </SIconItemContainer>
    );
};

IconItem.propTypes = {
    children: node.isRequired,
    title: string.isRequired,
    info: string.isRequired,
};
