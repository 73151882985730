import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { UnderlinedSectionTitle } from 'components/underlined-section-title';
import { CONSTANTS } from 'constants/styles/constants';

const SContainer = styled(Container)`
    color: var(--white-color);

    > :first-child {
        margin-bottom: 5rem;
    }
`;

const SSubContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: start;

    ${CONSTANTS.MEDIA.max768`
      flex-direction: column;
      gap: 3.75rem;
    `}
`;

const SStepContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-content: center;
`;

const SStepSpan = styled.span`
    font-size: 7rem;
    font-weight: 800;
    -webkit-text-stroke: ${({ secondary }) =>
        `2px var(${secondary ? '--tertiary-color' : '--additional-color'})`};
    color: var(--secondary-color);
`;

const SInfoSpan = styled.span`
    font-size: 1.375rem;
    text-align: center;
    padding: 0 2rem;

    ${CONSTANTS.MEDIA.max768`
      padding: 0 5rem;
    `}

    ${CONSTANTS.MEDIA.max440`
      padding: 0 2.5rem;
    `}
`;

const IDS = [
    'view-job-offer.recruitment-steps.stepOne',
    'view-job-offer.recruitment-steps.stepTwo',
    'view-job-offer.recruitment-steps.stepThree',
    'view-job-offer.recruitment-steps.stepFour',
];

export const RecruitmentSteps = () => {
    return (
        <SContainer>
            <UnderlinedSectionTitle label="view-job-offer.recruitment-steps.title" />
            <SSubContainer>
                {IDS.map((id, index) => (
                    <SStepContainer key={id}>
                        <SStepSpan secondary={index % 2}>{`0${
                            index + 1
                        }`}</SStepSpan>
                        <SInfoSpan>
                            <FormattedMessage id={id} />
                        </SInfoSpan>
                    </SStepContainer>
                ))}
            </SSubContainer>
        </SContainer>
    );
};
