import React from 'react';
import styled from 'styled-components';
import { Container } from 'components/container';
import { UnderlinedSectionTitle } from 'components/underlined-section-title';
import { CONSTANTS } from 'constants/styles/constants';
import CodeIcon from 'svgs/careers/job-offer/ic-code.svg';
import PeopleIcon from 'svgs/careers/job-offer/ic-people.svg';
import RoutingIcon from 'svgs/careers/job-offer/ic-routing.svg';

import { IconItem } from './components/icon-item';

const SSubContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding: 6.25rem 6.5rem;
    background: var(--black-pearl-color-70);
    box-shadow: 0px 1.5rem 2.625rem var(--black-color-15);
    border-radius: 1.5rem;

    > :first-child {
        margin-bottom: 0;
    }

    ${CONSTANTS.MEDIA.max900`
       padding: 3.25rem 3.5rem;
    `}

    ${CONSTANTS.MEDIA.max500`
       padding: 6.25rem 1.75rem;
    `}
`;

const SIconsContainer = styled.div`
    width: 100%;
    display: flex;
    gap: 4.75rem;

    ${CONSTANTS.MEDIA.max768`
       flex-direction: column;
    `}
`;

export const JoinUs = () => {
    return (
        <Container>
            <SSubContainer>
                <UnderlinedSectionTitle label="view-job-offer.join-us.title" />
                <SIconsContainer>
                    <IconItem
                        title="view-job-offer.join-us.buildAppsWithTeam.title"
                        info="view-job-offer.join-us.buildAppsWithTeam.info"
                    >
                        <PeopleIcon />
                    </IconItem>
                    <IconItem
                        title="view-job-offer.join-us.forgeOwnPath.title"
                        info="view-job-offer.join-us.forgeOwnPath.info"
                    >
                        <RoutingIcon />
                    </IconItem>
                    <IconItem
                        title="view-job-offer.join-us.shapeProduct.title"
                        info="view-job-offer.join-us.shapeProduct.info"
                    >
                        <CodeIcon />
                    </IconItem>
                </SIconsContainer>
            </SSubContainer>
        </Container>
    );
};
