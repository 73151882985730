import React, { useRef } from 'react';
import styled from 'styled-components';
import { CONSTANTS } from 'constants/styles/constants';
import { any, arrayOf, bool, shape, string } from 'prop-types';

import { PerksAndBenefits } from '../components/perks-and-benefits';

import { AppliedPositionInfo } from './components/applied-position-info';
import { ApplyNowBanner } from './components/apply-now-banner';
import { FooterImages } from './components/footer-images';
import { HeaderInfo } from './components/header-info';
import { JoinUs } from './components/join-us';
import { RecruitFormSection } from './components/recruit-form-section';
import { RecruitmentSteps } from './components/recruitment-steps';

const SJobOfferView = styled.div`
    width: 100%;
    padding-top: ${CONSTANTS.MARGIN.PAGE_BIG};
    background-color: var(--secondary-color);
`;

export const JobOffer = ({ jobOffer }) => {
    const {
        title,
        category,
        price,
        technologies: { technologies: technologiesData },
        requirementsList: { requirements: requirementsData },
        whatYouCanGainList: { whatYouCanGain: whatYouCanGainData },
    } = jobOffer;

    const formSectionRef = useRef();

    const scrollToForm = () => {
        formSectionRef.current?.scrollIntoView({
            behavior: 'smooth',
        });
    };

    return (
        <SJobOfferView>
            <HeaderInfo title={title} category={category} price={price} />
            <RecruitFormSection ref={formSectionRef} title={title} />
            <AppliedPositionInfo
                technologies={technologiesData}
                requirements={requirementsData}
                whatYouCanGain={whatYouCanGainData}
            />
            <PerksAndBenefits />
            <RecruitmentSteps />
            <JoinUs />
            <ApplyNowBanner onClick={scrollToForm} />
            <FooterImages />
        </SJobOfferView>
    );
};

JobOffer.propTypes = {
    location: shape({
        pathname: string,
    }),
    jobOffer: shape({
        title: string.isRequired,
        category: string.isRequired,
        price: string,
        mainDeal: string,
        areOtherDealsAvailable: bool,
        officeAddress: string,
        typeOfWork: string,
        description: any,
        technologies: shape({
            technologies: arrayOf(string),
        }),
        benefitsText: string,
        benefitsList: shape({
            benefits: arrayOf(string),
        }),
        requirementsText: string,
        requirementsList: shape({
            requirements: arrayOf(string),
        }),
        whatYouCanGainText: string,
        whatYouCanGainList: shape({
            whatYouCanGain: arrayOf(string),
        }),
    }).isRequired,
};
