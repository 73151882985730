import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { Button } from 'components/button-new';
import { Container } from 'components/container';
import { HeaderFourth } from 'components/header-fourth';
import { HeaderThird } from 'components/header-third';
import { MainTitle } from 'components/main-title';
import { PATHS } from 'constants/paths';
import { CONSTANTS } from 'constants/styles/constants';
import { string } from 'prop-types';
import LocationIcon from 'svgs/careers/job-offer/ic-location.svg';
import TwoNotesIcon from 'svgs/careers/job-offer/ic-two-notes.svg';
import WalletCheckIcon from 'svgs/careers/job-offer/ic-wallet-check.svg';
import ArrowLeft from 'svgs/icons/arrow-left.svg';

const SInfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 4.375rem;
`;

const STitle = styled(MainTitle)`
    margin-top: 0.75rem;
    text-align: center;
    color: var(--white-color);

    ${CONSTANTS.MEDIA.max1024`
       margin: 0.5rem;
    `}
`;

const SHeaderFourth = styled(HeaderFourth)`
    color: var(--additional-color);
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    font-size: 0.75rem;
    text-align: center;
`;

const SButton = styled(Button)`
    padding: 0.8125rem 2.375rem;
    height: 3rem;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.875rem;
    box-shadow: var(--button-shadow);
`;

const SIconsContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    ${CONSTANTS.MEDIA.max900`
       flex-direction: column;
       gap: 3.75rem;
    `}
`;

const SIconItemContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--white-color);
`;

const SIconWrapper = styled.div`
    width: 6.125rem;
    height: 6.125rem;
    padding: 1.75rem;
    margin-bottom: 1.25rem;
    background: var(--tertiary-color-10);
    border-radius: 50%;
    text-align: center;
`;

const SIconHeader = styled(HeaderThird)`
    font-size: 1.75rem;
    font-weight: 400;
    margin-bottom: 0.25rem;
`;

const IconItem = ({ children, title, info, isPrice }) => {
    return (
        <SIconItemContainer>
            <SIconWrapper>{children}</SIconWrapper>
            <SIconHeader>
                {isPrice ? title : <FormattedMessage id={title} />}
            </SIconHeader>
            {info && (
                <strong>
                    <FormattedMessage id={info} />
                </strong>
            )}
        </SIconItemContainer>
    );
};

export const HeaderInfo = ({ category, title, price }) => {
    return (
        <Container>
            <SInfoContainer>
                <SButton
                    component="gatsby-link"
                    to={PATHS.CAREERS}
                    variant="additional-link"
                >
                    <ArrowLeft />
                    <FormattedMessage id="view-job-offer.header-info.backToCareers" />
                </SButton>
                <SHeaderFourth>{category}</SHeaderFourth>
                <STitle withAfterSelector={false}>{title}</STitle>
            </SInfoContainer>
            <SIconsContainer>
                <IconItem title="view-job-offer.header-info.fullyRemote">
                    <LocationIcon />
                </IconItem>
                <IconItem title={price} isPrice>
                    <WalletCheckIcon />
                </IconItem>
                <IconItem
                    title="view-job-offer.header-info.b2b"
                    info="view-job-offer.header-info.cooperationTypes"
                >
                    <TwoNotesIcon />
                </IconItem>
            </SIconsContainer>
        </Container>
    );
};

HeaderInfo.propTypes = {
    title: string.isRequired,
    category: string.isRequired,
    price: string.isRequired,
};
