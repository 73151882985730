import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderThird } from 'components/header-third';
import { CONSTANTS } from 'constants/styles/constants';
import { arrayOf, bool, string } from 'prop-types';

const resolveColor = (secondary) =>
    secondary ? '--additional-color' : '--tertiary-color';

const SSectionContainer = styled.div`
    width: 100%;
    padding: 3.125rem 3.625rem;
    border: ${({ secondary }) => `3px solid var(${resolveColor(secondary)})`};
    box-shadow: ${({ secondary }) =>
        `0.5rem 0.5rem 0px var(${resolveColor(secondary)})`};
    border-radius: 1rem;
    color: var(--white-color);

    ${CONSTANTS.MEDIA.max500`
       padding: 2.5rem;
    `}
`;

const SHeaderThird = styled(HeaderThird)`
    color: ${({ secondary }) => `var(${resolveColor(secondary)})`};
    text-transform: uppercase;
    margin-bottom: 0.625rem;

    ${CONSTANTS.MEDIA.max500`
      font-size: 1.25rem;
    `}
`;

const SLi = styled.li`
    font-size: 1.125rem;
    line-height: 2.375rem;

    ::marker {
        color: ${({ secondary }) => `var(${resolveColor(secondary)})`};
    }
`;

const formatTechnologyText = (text) => {
    return `${text.replace('_', ' [')}]`;
};

export const Section = ({ title, data, secondary }) => {
    const isTechnologySection =
        title === 'view-job-offer.applied-position-info.technologies';

    return (
        <SSectionContainer secondary={secondary}>
            <SHeaderThird secondary={secondary}>
                <FormattedMessage id={title} />
            </SHeaderThird>
            <ul>
                {data.map((item) => (
                    <SLi key={item} secondary={secondary}>
                        {isTechnologySection
                            ? formatTechnologyText(item)
                            : item}
                    </SLi>
                ))}
            </ul>
        </SSectionContainer>
    );
};

Section.propsTypes = {
    title: string.isRequired,
    data: arrayOf(string).isRequired,
    secondary: bool,
};

Section.defaulProps = {
    secondary: false,
};
